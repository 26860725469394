export const PROJECT_DIALOG_CONTENT_IMAGES_TRANSITIONS_PROPS = {
    initial: {
        opacity: 0,
        y: '50px'
    },
    animate: {
        opacity: 1,
        y: 0
    },
    exit: {
        opacity: 0,
        y: '50px'
    }
};
